import Vue from 'vue'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css";
import App from './App.vue'
import router from './router'
import Firebase from './firebase'
import VueMask from 'v-mask'
import axios from 'axios'
import moment from 'moment'
import pagarme from 'pagarme/browser'
import VueApexCharts from 'vue-apexcharts'
import firebase from 'firebase/app'
import './assets/scss/app.scss'
import 'popper.js'
import 'bootstrap'
import VueScrollTo from 'vue-scrollto'
import CookieLaw from 'vue-cookie-law'
import VueCryptojs from 'vue-cryptojs'
import VueCookie from 'vue-cookie'
import VCalendar from 'v-calendar'
import FloatingVue from 'floating-vue'

Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  containerClassName: 'my-toast',
  maxToasts: 1,
  position: 'bottom-center',
  timeout: 5000,
  newestOnTop: true,
  draggable: false,
  closeButton: false,
  pauseOnFocusLoss: false,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  pauseOnHover: false,
  closeOnClick: false,
  toastClassName: 'my-custom-toast-class',
  bodyClassName: ['custom-class-1', 'custom-class-2']
})

let app
Vue.use(FloatingVue)
Vue.use(VueCryptojs)
Vue.use(CookieLaw)
Vue.use(VueScrollTo)
Vue.use(Firebase)
Vue.use(VueMask)
Vue.use(VueApexCharts)
Vue.use(VueScrollTo)
Vue.use(VCalendar)
Vue.use(VueCookie)
Vue.component('apexchart', VueApexCharts)
Vue.prototype.$http = axios
Vue.prototype.$pagarme = pagarme
Vue.prototype.moment = moment
Vue.config.productionTip = false
Vue.prototype.$userPlan = ''
Vue.config.productionTip = false

// initGoogleApi().then(() => {
//   new Vue({
//     render: h => h(App)
//   }).$mount('#app')
// }, (error) => {
//   console.error('Error initializing Google API: ', error)
// })

firebase.apps[0].auth().onAuthStateChanged(user => {
  document.title = 'SimplesHub | '
  window.uid = user ? user.uid : null
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})
